<template>
  <h1>Terima Kasih. Permohonan berhasil diajukan.</h1>
</template>
<script>
export default {
  data() {
    return {

    }
  },
}
</script>
